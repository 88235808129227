import React, { useEffect } from 'react';
import './Testimonial.css'; // Import the custom CSS file

const Testimonial = () => {


  return (
    <div id='testimonial' className="testimonials-container bg-white text-blue-700 py-12">
  
  <div class="elfsight-app-166ca150-e541-40d2-ab72-1e5e44816b01" data-elfsight-app-lazy></div>
    </div>
  );
};

export default Testimonial;
