import React from 'react';
import { motion } from 'framer-motion';
import { FaExclamationTriangle } from 'react-icons/fa';

const ProblemStatement = () => {
  const problems = [
    "Feeling undervalued despite your talent and hard work?",
    "Stuck in a role where you're not learning or growing?",
    "Lacking a platform to showcase your creativity and technical skills?",
    "Seeking a more collaborative and innovative work environment?",
  ];

  return (
    <section className="mb-16">
      <h2 className="text-3xl font-semibold text-blue-800 mb-6 flex items-center justify-center">
        <FaExclamationTriangle className="mr-2 text-yellow-500" />
        The Challenges You Might Be Facing:
      </h2>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-lg p-6 border-l-4 border-yellow-500"
      >
        <ul className="list-none space-y-4">
          {problems.map((problem, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="flex items-start"
            >
              <span className="text-yellow-500 mr-2">•</span>
              <span className="text-gray-700">{problem}</span>
            </motion.li>
          ))}
        </ul>
      </motion.div>
    </section>
  );
};

export default ProblemStatement;

