import React from 'react';
import { motion } from 'framer-motion';

const Header = () => {
  return (
    <header className="text-center mb-12">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-5xl font-extrabold text-gray-900 mb-4"
      >
        Join Our <span className="text-indigo-600">Visionary</span> Team
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="text-xl text-gray-600 max-w-2xl mx-auto"
      >
        Are you ready to elevate your career and make a real impact in the tech world?
      </motion.p>
    </header>
  );
};

export default Header;

