import React from 'react';
import { motion } from 'framer-motion';
import { MdDeveloperMode, MdDesignServices, MdCastForEducation } from 'react-icons/md';

const RoleDetails = () => {
  const roleDetails = [
    { 
      title: "Software Development", 
      icon: <MdDeveloperMode className="text-5xl text-blue-500 mb-4" />,
      description: "Working on cutting-edge projects with the latest technologies, turning ideas into impactful solutions.",
      tasks: [
        "Build and deploy modern web and mobile applications.",
        "Work with technologies like React, Node.js, Python, and more.",
        "Solve real-world problems with scalable solutions.",
        "Collaborate with cross-functional teams to deliver high-quality products."
      ]
    },
    { 
      title: "Graphic Designing", 
      icon: <MdDesignServices className="text-5xl text-green-500 mb-4" />,
      description: "Creating designs that truly stand out and build stunning visuals that leave lasting impressions.",
      tasks: [
        "Craft visually compelling designs for web, social media, and branding.",
        "Use tools like Adobe Suite, Figma, and Canva.",
        "Collaborate with a creative team to deliver stunning visuals.",
        "Stay up-to-date with the latest design trends and technologies."
      ]
    },
    { 
      title: "Technical Training", 
      icon: <MdCastForEducation className="text-5xl text-purple-500 mb-4" />,
      description: "Lead engaging sessions, mentoring passionate learners, and changing lives with our expertise.",
      tasks: [
        "Teach and mentor students in web development, Python, AI, and more.",
        "Develop innovative teaching methodologies.",
        "Make learning fun, interactive, and impactful.",
        "Create comprehensive course materials and learning resources."
      ]
    },
  ];

  return (
    <section className="mb-20">
      <h2 className="text-3xl font-bold text-blue-800 mb-8 text-center">Your Role, Your Opportunity</h2>
      <div className="grid md:grid-cols-3 gap-8">
        {roleDetails.map((role, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="bg-white rounded-lg shadow-lg p-6 border-t-4 border-blue-500 hover:shadow-xl transition-all duration-300"
          >
            <div className="flex justify-center">{role.icon}</div>
            <h3 className="text-xl font-semibold mb-4 text-blue-600 text-center">{role.title}</h3>
            <p className="text-gray-600 mb-4 text-center">{role.description}</p>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              {role.tasks.map((task, taskIndex) => (
                <li key={taskIndex}>{task}</li>
              ))}
            </ul>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default RoleDetails;

