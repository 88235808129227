// src/components/HeroSection.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaLaptopCode, FaUserCheck, FaMobileAlt, FaWhatsapp } from 'react-icons/fa';
import img1 from '../images/Student-linker-final-1536x861.png';

const Eyecatch = () => {
  const { ref, inView } = useInView({ triggerOnce: true });

  const handleGetQuote = () => {
    const message = encodeURIComponent("Hi! I'm interested in getting a quote for website development.");
    window.open(`https://wa.me/919042621178?text=${message}`, '_blank');
  };

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
      transition={{ duration: 1 }}
      className="flex flex-col lg:flex-row items-center bg-gradient-to-br from-blue-600 to-blue-500 p-8"
    >
      <div className="flex-1 flex justify-center mb-8 lg:mb-0">
        <motion.img
          src={img1}
          alt="Website Display"
          className="max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg "
          initial={{ opacity: 0 }}
          animate={{ opacity: inView ? 1 : 0 }}
          transition={{ duration: 1 }}
        />
      </div>
      <motion.div 
        className="flex-1 py-6 text-white text-left"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 50 }}
        transition={{ duration: 1 }}
      >
        <h1 className="text-3xl md:text-4xl font-semibold md:font-bold mb-4">
          Transform Your Digital Presence
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <div className="bg-gradient-to-br  from-indigo-600 to-indigo-700 p-4 rounded-lg shadow-lg 
             transition-all duration-300 hover:shadow-xl 
            border hover:border-gray-50 h-[200px] flex flex-col justify-start">
            <h3 className="text-xl font-semibold mb-2">🛒 E-commerce Solutions</h3>
            <p className="text-sm text-gray-100">
              Custom online stores with secure payment gateways and seamless user experience.
            </p>
          </div>
          
          <div className="bg-gradient-to-br from-purple-600 to-purple-700 p-4 rounded-lg shadow-lg 
            transition-all duration-300 hover:shadow-xl 
            border hover:border-gray-50 h-[200px] flex flex-col justify-start">
            <h3 className="text-xl font-semibold mb-2">💻 Custom Web Development</h3>
            <p className="text-sm text-gray-100">
              Tailored websites built from scratch using modern technologies like React, Next.js, and Node.js.
            </p>
          </div>
          
          <div className="bg-gradient-to-br from-pink-600 to-pink-700 p-4 rounded-lg shadow-lg 
            transition-all duration-300 hover:shadow-xl 
            border hover:border-gray-50 h-[200px] flex flex-col justify-start">
            <h3 className="text-xl font-semibold mb-2">⚡ WordPress Development</h3>
            <p className="text-sm text-gray-100">
              Professional WordPress websites with custom themes, plugins, and optimized performance.
            </p>
          </div>
        </div>
        
        <button
          onClick={handleGetQuote}
          className="mb-8 px-8 py-4 bg-gradient-to-r from-emerald-400 to-emerald-500 text-white rounded-lg 
            font-semibold flex items-center justify-center hover:from-emerald-500 hover:to-emerald-600 
            transform hover:scale-105 transition-all duration-300 shadow-lg group"
        >
          <FaWhatsapp className="text-xl mr-2 group-hover:animate-bounce" />
          Get a Free Quote
        </button>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="px-5 py-3 rounded-lg bg-white/20 backdrop-blur-sm text-center 
            hover:bg-white/30 transition-all duration-300 hover:transform hover:scale-105 
            border border-white/10 shadow-lg">
            <FaLaptopCode className="inline-block mb-2 text-2xl text-emerald-300" />
            <div className="font-medium">UI/UX DESIGN</div>
          </div>
          <div className="px-5 py-3 rounded-lg bg-white/20 backdrop-blur-sm text-center 
            hover:bg-white/30 transition-all duration-300 hover:transform hover:scale-105 
            border border-white/10 shadow-lg">
            <FaUserCheck className="inline-block mb-2 text-2xl text-emerald-300" />
            <div className="font-medium">USER FRIENDLY</div>
          </div>
          <div className="px-5 py-3 rounded-lg bg-white/20 backdrop-blur-sm text-center 
            hover:bg-white/30 transition-all duration-300 hover:transform hover:scale-105 
            border border-white/10 shadow-lg">
            <FaMobileAlt className="inline-block mb-2 text-2xl text-emerald-300" />
            <div className="font-medium">RESPONSIVENESS</div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Eyecatch;
