import React from 'react';
import { motion } from 'framer-motion';
import { FaLightbulb, FaChartLine, FaStar, FaBalanceScale, FaGlobeAmericas, FaHandshake } from 'react-icons/fa';

const Benefits = () => {
  const benefits = [
    { icon: <FaLightbulb />, title: 'Innovative Environment', description: 'Work with a team that challenges norms and inspires innovation.' },
    { icon: <FaChartLine />, title: 'Rapid Growth', description: 'Clear paths to growth and skill development in a fast-paced, learning-oriented culture.' },
    { icon: <FaStar />, title: 'Impactful Work', description: 'Be part of projects that shape industries and empower learners worldwide.' },
    { icon: <FaBalanceScale />, title: 'Work-Life Harmony', description: 'Enjoy flexible schedules, remote options, and a culture that values your well-being and personal growth.' },
    { icon: <FaGlobeAmericas />, title: 'Global Exposure', description: 'Collaborate with diverse teams and clients from around the world.' },
    { icon: <FaHandshake />, title: 'Inclusive Culture', description: 'Be part of a workplace that celebrates diversity and fosters belonging.' },
  ];

  return (
    <section className="mb-20">
      <h2 className="text-3xl font-bold text-blue-800 mb-8 text-center">What Makes Us Different?</h2>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {benefits.map((benefit, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 border border-blue-100"
          >
            <div className="text-4xl text-blue-500 mb-4">{benefit.icon}</div>
            <h3 className="text-xl font-semibold text-blue-700 mb-3">{benefit.title}</h3>
            <p className="text-gray-600">{benefit.description}</p>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Benefits;

