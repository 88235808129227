import React from 'react';
import { FaLightbulb } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="mt-16 text-center pb-8">
      <h2 className="text-2xl font-semibold text-blue-800 mb-4">Don't Wait — Your Dream Career Starts Here!</h2>
      <p className="text-gray-600 flex items-center justify-center">
        <FaLightbulb className="mr-2 text-yellow-500" />
        At Divine Infotech, we don't just hire employees; we invest in dreamers, creators, and leaders.
      </p>
      <div className="mt-8 text-sm text-gray-500">
        © {new Date().getFullYear()} Divine Infotech. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;

