import React from 'react';
import { motion } from 'framer-motion';

const TrustedCompanies = () => {
  const companies = ['Google', 'Amazon', 'Microsoft', 'Apple', 'Facebook'];

  return (
    <section className="mt-16 mb-20">
      <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">Trusted By Industry Leaders</h2>
      <div className="flex flex-wrap justify-center items-center gap-8">
        {companies.map((company, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="text-gray-400 font-bold text-xl"
          >
            {company}
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default TrustedCompanies;

