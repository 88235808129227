import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Header from './Careers/Header';
import ProblemStatement from './Careers/ProblemStatement';
import RoleDetails from './Careers/RoleDetails';
import Benefits from './Careers/Benefits';
import ApplicationForm from './Careers/ApplicationForm';
import TrustedCompanies from './Careers/TrustedCompanies';
import Footer from './Careers/Footer';

const Careerss = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-7xl mx-auto"
      >
        <Header />
        <ProblemStatement />
        <RoleDetails />
        <Benefits />
        <ApplicationForm setShowModal={setShowModal} />
        <TrustedCompanies />
        <Footer />
      </motion.div>
    </div>
  );
};

export default Careerss;

